import React, { useState, useEffect, useRef } from 'react';

import Speech from 'speak-tts';

import { BTNPlay, BTNPause, BTNAudio } from '../../Atoms/Icons/Icons';

import './AudioComponent.scss';
let classNames = require('classnames');

const AudioComponent = ({ text, textToRead, color }) => {
  const [playing, setPlaying] = useState(false);
  const [speech, setSpeech] = useState(false);
  const [speechPlay, setSpeechPlay] = useState(false);

  useEffect(() => {
    setSpeech(new Speech());
    return () => { if (speech) { speech.pause(); speech.resume() } };
  }, []);

  useEffect(() => {
    if (!speech) { return false }

    speech.init({
      volume: 0.5,
      lang: 'fr-FR',
      rate: 1,
      pitch: 1,
      //'voice':'Google UK English Male',
      //'splitSentences': false,
      listeners: {
        onvoiceschanged: voices => {
          //console.log('Voices changed', voices);
        }
      }
    })
      .then(data => {
        //console.log('Speech is ready', data);
        //_addVoicesList(data.voices);
        // _prepareSpeakButton(speech);
      })
      .catch(e => {
        console.error('An error occured while initializing : ', e);
      });
    return () => { speech.pause() };
  }, [speech]);

  const toggle = (e) => {
    e.preventDefault();
    if (!speech) { return false }
    if (playing) {
      speech.pause();
    } else {
      speech
        .speak({
          text: textToRead.replaceAll('\n', ' '),
          queue: false,
          listeners: {
            onstart: () => {
              console.log('Start utterance');
              setSpeechPlay(true);
            },
            onend: () => {
              console.log('End utterance');
              setSpeechPlay(false);
            },
            onresume: () => {
              console.log('Resume utterance');
              setSpeechPlay(false);
            },
            onboundary: event => {
              console.log(
                event.name +
                ' boundary reached after ' +
                event.elapsedTime +
                ' milliseconds.'
              );
            }
          }
        })
        .then(data => {
          console.log('Success !', data);
        })
        .catch(e => {
          console.error('An error occurred :', e);
        });
    }

    setPlaying(!playing);
  };

  return (
    <div className={classNames('audio_component')}>
      {
        playing ? (
          <div className="playing_state">
            <a href="#" className="playing" onClick={toggle}>
              <BTNPause />
            </a>

            <div className="progress_bar">
              <span className={classNames({ 'animate': speechPlay })}></span>
            </div>
          </div>
        ) : (
          <div className="default_state">
            <a href="#" className="default" onClick={toggle}>
              <BTNAudio />
            </a>
            <a href="#" className="play" onClick={toggle}>
              <BTNPlay />
            </a>

            <p className={color ? color : ''}>{text}</p>
          </div>
        )
      }
    </div >
  );
};

AudioComponent.default = {
  text: 'Lister Audio Version',
  src: ''
};

export default AudioComponent;
