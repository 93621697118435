import React, { useRef, useState, useEffect } from 'react';

import { Link } from 'gatsby';
import Img from 'gatsby-image';

import ArrowButton from '../../Atoms/ArrowButton/ArrowButton';
import {
  IconLectureTime,
  HorizontalYellowLineShape,
  ArrowIconGoRight
} from '../../Atoms/Icons/Icons';
import { Button } from '../../Molecules/Button/Button';
import SocialShare from '../../Molecules/SocialShare/SocialShare';

import './Carousel.scss';

let classNames = require('classnames');

const slideWidth = 200;

const createItem = (slides, position, idx) => {
  const item = {
    info: slides[idx].info,
    image: slides[idx].image,
    reading_time: slides[idx].reading_time
  };

  const active_slide = slides.length < 3 ? 1 : 3;

  switch (position) {
    case active_slide - 1:
      item.styles = {
        transform: `translateX(${position * slideWidth}px)`,
        width: '740px',
        height: '100%'
      };
      item.class_name = 'active';
      break;
    case active_slide + 1:
    case active_slide:
      item.styles = {
        transform: `translateX(${position * slideWidth + 540}px)`
      };
      item.class_name = '';
      break;
    case active_slide + 2:
      item.styles = {
        transform: `translateX(${position * slideWidth + 540}px)`
      };
      item.class_name = '';
      break;
    case active_slide + 3:
      item.styles = {
        transform: `translateX(${position * slideWidth + 540}px)`,
        opacity: 0
      };
      item.class_name = '';
      break;
    default:
      item.styles = {
        transform: `translateX(${position * slideWidth}px)`,
        opacity: 0
      };
      item.class_name = '';
      break;
  }

  return item;
};

const CarouselSlideItem = ({ slides, pos, idx, sliderMode }) => {
  const item = createItem(slides, pos, idx);
  sliderMode = sliderMode || '';
  const image_sources = [
    item.image?.childImageSharp?.desktop
  ];

  return (
    <li className={classNames('carousel__slide-item', item.class_name)} style={item.styles}>
      <div className="carousel__slide-item-img-link">
        {sliderMode === 'articles' &&
          <div className="info_share">
            <p className="lecture_time">
              <IconLectureTime className="time_icon" />
              <span>{item.reading_time}</span>
              <HorizontalYellowLineShape className="bottom_line" />
            </p>
            <SocialShare
              hidemobile={true}
              vertical={true}
            />
          </div>
        }
        <div className="visuel">
          {item.image &&
            <Img
              className="fixed"
              alt={item.info.text}
              style={{ height: '100%', width: '100%' }}
              imgStyle={{ objectFit: 'cover' }}
              fixed={image_sources}
            />
          }
          {item.image &&
            <Img
              className="fluid"
              alt={item.info.text}
              style={{ height: '100%', width: '100%' }}
              imgStyle={{ objectFit: 'cover' }}
              fluid={image_sources}
            />
          }
        </div>
      </div>
      <div className={classNames('info', 'info_style_' + sliderMode)}>
        <p className="title">
          {item.info.link ? (
            <Link to={item.info.link}>{item.info.text} {sliderMode === 'articles' && (<ArrowIconGoRight fill="#ffffff" />)}</Link>
          ) : (
            item.info.text
          )}
        </p>
        {item.info.description &&
          <p>{item.info.description}</p>
        }
        <p></p>
        {sliderMode != 'articles' && item.info.link_label && item.info.link && (
          <Button
            primary={true}
            label={item.info.link_label}
            link={item.info.link + (item.info.anchor !== undefined ? item.info.anchor : '')}
          />
        )}
      </div>
    </li>
  );
};

const Carousel = ({ slides, sliderMode, textColor }) => {
  const _slides = slides;
  const keys = Array.from(Array(_slides.length).keys());
  const [items, setItems] = React.useState(keys);
  const bigLength = items.length;
  const prevClick = (jump = 1) => {
    setItems((prev) => {
      return prev.map((_, i) => prev[(i + jump) % bigLength]);
    });
  };

  const nextClick = (jump = 1) => {
    setItems((prev) => {
      return prev.map((_, i) => prev[(i - jump + bigLength) % bigLength]);
    });
  };

  return (
    <div className={classNames('carousel__wrap', 'slider_mode_' + sliderMode, { 'no_slider': bigLength < 3 })}>
      <div className="carousel__inner">

        <div className="carousel__container">
          <ul className="carousel__slide-list" style={{ width: 740 + (_slides.length - 1) * slideWidth + 10 * 20 + 'px' }}>
            {items.map((pos, i) => (
              <CarouselSlideItem
                slides={_slides}
                key={i}
                idx={i}
                pos={pos}
                sliderMode={sliderMode}
              />
            ))}
          </ul>
        </div>

        <ArrowButton
          direction='prev'
          callback={prevClick}
          text="Prec."
          textColor={textColor}
        />
        <ArrowButton
          direction='next'
          callback={nextClick}
          text="Suiv."
          textColor={textColor}
        />

      </div>
    </div>
  );
};

export default Carousel;
