import React, { useEffect, useState } from 'react';

import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import { useIntl } from '../../../../plugins/publicis-gatsby-plugin-i18n';
import AudioComponent from '../../../components/AudioComponent/AudioComponent';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import Carousel from '../../../components/Carousel/Carousel';
import IntroPage from '../../../components/ContentType/IntroPage/IntroPage';
import TextVisuelTwoCol from '../../../components/ContentType/TextVisuelTwoCol/TextVisuelTwoCol';
import KeyNumbers from '../../../components/KeyNumbers/KeyNumbers';
import PageBanner from '../../../components/PageBanner/PageBanner';
import ScrollPageNav from '../../../components/ScrollPageNav/ScrollPageNav';
import Seo from '../../../components/Seo/Seo';
import SliderComponent from '../../../components/SliderComponent/SliderComponent';
import Layout from '../../../Layout';
import { Button } from '../../../Molecules/Button/Button';
import ShapeBackground from '../../../Molecules/ShapeBackground/ShapeBackground';
import TitlePage from '../../../Molecules/TitlePage/TitlePage';
import TitleSection from '../../../Molecules/TitleSection/TitleSection';
import { getImage, getBreadCrumb, removeHtmlTag, getReOrderedCarouselSlides } from '../../../Utils/Utils';

import './styles.scss';
let classNames = require('classnames');

const EngagementEnjeux = ({ data }) => {
  const intl = useIntl();
  const imagesArray = data?.allImages?.edges;
  const pageData = data?.pageData?.edges[0].node;
  const allMenu = data.allMenu.edges;
  const breadCrumb = getBreadCrumb(allMenu, pageData.path?.alias);

  const [innerNavList, setInnerNavList] = useState([]);
  const [allText, setAllText] = useState('');

  let sliders = [];
  let sectionArr = [];
  let leftRightBlocks = [];
  let sectionCount = 0;
  let globalText = '';

  const slider_settings = {
    className: 'arrow_bottom_slider',
    centerMode: false,
    infinite: false,
    centerPadding: '100px',
    slidesToShow: 1.5,
    speed: 500,
    initialSlide: 0,
    arrows: true,
    responsive: [{
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        dots: false
      }
    }]
  };

  useEffect(() => {
    setInnerNavList(sectionArr);
    setAllText(removeHtmlTag(`${pageData.title}.${globalText}`));
    console.log()
  }, []);

  return (
    <Layout imagesArray={imagesArray}>
      <Seo title={pageData.title} description={pageData.field_sous_titre} />
      <div className={classNames('page_template', 'page_engagement_enjeux', !pageData?.field_padding && 'zero_bottom_padding')}>
        <ScrollPageNav
          data={innerNavList}
        />
        <PageBanner visuel={getImage(imagesArray, pageData?.relationships?.field_image?.drupal_internal__mid)?.childImageSharp?.fluid} scroll={true}>
          <div className="wrapper_page">
            <Breadcrumb
              page_banner={true}
              data={{
                //gparent: { title: breadCrumb?.grandParent?.name, url: breadCrumb?.grandParent?.link },
                parentPage: { title: breadCrumb?.parent?.name, url: breadCrumb?.parent?.link },
                currentPage: { title: breadCrumb?.current?.name, url: breadCrumb?.current?.link },
                locale: pageData.langcode
              }}
            />
            <div className="title_audio"> {/* !!!!!! if audio add title_autio if not put nothing */}
              <TitlePage
                color="color_white"
                title={pageData.title}
                description={pageData.field_sous_titre}
              />
              {/* { {allText && pageData.field_version_audio && allText.length > 30 && } */}
              <AudioComponent
                text={intl.formatMessage({ id: 'detailsActivities.button.listen.label' })}
                textToRead={allText}
                color="color_white"
              />
            </div>
          </div>
        </PageBanner>
        {pageData.relationships?.field_blocs?.map((block, i) => {
          if (block.field_title?.processed && pageData.field_progress) {
            sectionCount++;
            sectionArr.push({
              title: '0' + sectionCount + '. ' + block.field_title?.processed,
              section: 'scroll_to_' + sectionCount
            });
          }
          switch (block.__typename) {
            case 'block_content__block_texte':
              leftRightBlocks = [];
              globalText += `${block.field_title?.processed}.${block.field_subtitle?.processed ? block.field_subtitle?.processed : ''}.${block.body?.processed}`;
              block.relationships?.field_section?.forEach((item => {
                globalText += `${item.relationships?.field_bloc_texte[0]?.field_title?.processed}.${item.relationships?.field_bloc_texte[0]?.field_bloc_text_1.processed}`;
                leftRightBlocks.push(
                  {
                    text: item.relationships?.field_bloc_texte[0]?.field_bloc_text_1.processed,
                    title: item.relationships?.field_bloc_texte[0]?.field_title?.processed,
                    visuel: getImage(imagesArray, item?.relationships?.field_image?.drupal_internal__mid)?.childImageSharp,
                    arrowColor: item.relationships?.field_bloc_texte[0]?.field_theme_title,
                    field_no_padding: item.field_no_padding,
                    field_alignment: item.field_alignment
                  }

                );
              }));
              return (
                <div key={i}>

                  <IntroPage
                    customClass={'scroll_to_' + sectionCount}
                    title={block.field_title?.processed}
                    subtitle={block.field_subtitle?.processed}
                    visuel={getImage(imagesArray, block?.relationships?.field_image?.drupal_internal__mid)?.childImageSharp}
                    text={block.body?.processed}
                    size="border" //wrapper
                  />
                  {leftRightBlocks.length > 0 && <section className={classNames('section_content', 'block_1_section')}>
                    <ShapeBackground
                      top={block.field_backgroud_color === 'ice' ? 'right' : block.field_border_top && 'left'}
                      color={block.field_backgroud_color === 'ice' ? 'catskill_white' : (block.field_backgroud_color === 'navy' ? 'dark_bleu' : 'white')}
                      bottomInfinite={block.field_infinite_border}
                      bottom={block.field_border_bottom_display}
                    >
                      <div className="wrapper_page_xs">
                        {leftRightBlocks.map((block, index) => (
                          <TextVisuelTwoCol
                            key={index}
                            title={block.title}
                            colorTitle={block.arrowColor === 'theme_1' && 'color_yellow'}

                            visuel={block.visuel}
                            extremeLeft={(block?.field_no_padding && block?.field_alignment === 'left') ? true : false}
                            extremeRight={(block?.field_no_padding && block?.field_alignment === 'right') ? true : false}
                            alignLeft={(!block?.field_no_padding && block?.field_alignment === 'left') ? true : false}
                            alignRight={(!block?.field_no_padding && block?.field_alignment === 'right') ? true : false}
                            orderInverse={block?.field_alignment === 'right' ? true : false}
                            alignTop={true}
                            text={block.text}
                            colorDescription="color_black"
                            textFullWidth={(index % 2 == 0) ? false : true}

                            visuelAspectRatioMobile={1.34241245}
                            visuelAspectRatioDesktop={(index % 2 == 0) ? 1.04107143 : 0.699801193}
                            visuelMaxWidthDesktop={(index % 2 == 0) ? '100%' : 352}
                          />
                        ))}
                      </div>
                    </ShapeBackground>
                  </section>}

                </div>
              );

            case 'block_content__block_slider_innovation':
              sliders = [];
              globalText += `${block.field_title?.processed}.${block.body?.processed}`;
              block.relationships?.field_slides?.forEach((item => {
                sliders.push(
                  {
                    image: getImage(imagesArray, item?.relationships?.field_image?.drupal_internal__mid),
                    info: {
                      text: item.field_title.processed,
                      link: block.field_link?.url,
                      link_label: block.field_link?.title,
                      anchor: '?anchor=section-' + item?.relationships?.field_anchor?.drupal_internal__tid
                    },
                  }
                );
              }));
              sliders.push(...sliders);

              return (
                <section className={classNames('section_content', 'block_1_section', 'scroll_to_' + sectionCount)} key={i}>
                  <ShapeBackground
                    top={block.field_border_top && 'left'}
                    color={block.field_backgroud_color === 'ice' ? 'catskill_white' : (block.field_backgroud_color === 'navy' ? 'dark_bleu' : 'white')}
                    bottomInfinite={block.field_infinite_border}
                    bottom={block.field_border_bottom_display}
                  >
                    <div className="wrapper_page_xs">
                      <TitleSection
                        title={block.field_title?.processed}
                        type="arrow"
                        h2color={block.field_backgroud_color === 'ice' ? '' : (block.field_backgroud_color === 'navy' ? 'white' : '')}
                        h4color={block.field_backgroud_color === 'ice' ? '' : (block.field_backgroud_color === 'navy' ? 'white' : '')}
                        className={block.field_backgroud_color === 'ice' ? '' : (block.field_backgroud_color === 'navy' ? 'color_white' : '')}
                      />
                      <h5 className={block.field_backgroud_color === 'ice' ? '' : (block.field_backgroud_color === 'navy' ? 'color_white' : '')} dangerouslySetInnerHTML={{ __html: block.field_title?.processed }}></h5>
                      <div className={block.field_backgroud_color === 'ice' ? '' : (block.field_backgroud_color === 'navy' ? 'color_white' : '')} dangerouslySetInnerHTML={{ __html: block.body?.processed }} />

                    </div>
                    <div className={classNames('only_mobile_tablet')}>
                      <SliderComponent settings={slider_settings}>
                        {sliders.map((slide, i) => (
                          <div key={i}>
                            <div className="only_mobile">
                              {slide?.image &&
                                <Img
                                  style={{ width: '100%' }}
                                  imgStyle={{ objectFit: 'cover' }}
                                  fluid={{ ...slide.image?.childImageSharp.mobile, aspectRatio: 0.733333333 }}
                                />
                              }
                            </div>
                            <div className="only_tablet">
                              {slide?.image &&
                                <Img
                                  style={{ height: '100%', width: '100%' }}
                                  imgStyle={{ objectFit: 'cover' }}
                                  fluid={{ ...slide.image?.childImageSharp.desktop, aspectRatio: 1 }}
                                />
                              }
                            </div>
                            <div className="info_title_description">
                              <p>{slide.info.text}</p>
                              {slide.info.link &&
                                <Button
                                  primary={true}
                                  label={slide.info.link_label}
                                  link={slide.info.link}
                                />
                              }
                            </div>
                          </div>
                        ))}
                      </SliderComponent>
                    </div>
                    <div className="wrapper_page_xs">
                      <div className={classNames('only_desktop')}>
                        <Carousel slides={getReOrderedCarouselSlides(sliders)} sliderMode="" textColor={block.field_backgroud_color === 'ice' ? '' : (block.field_backgroud_color === 'navy' ? 'color_white' : '')} />
                      </div>
                    </div>
                  </ShapeBackground>
                </section>
              );

            case 'block_content__block_slider_nos_activites':
              let slides = [];
              globalText += `${block.field_title?.processed}.${block.body?.processed}`;
              block.relationships?.field_nos_activites?.forEach((item => {
                slides.push(
                  {
                    image: getImage(imagesArray, item?.relationships?.field_image?.drupal_internal__mid),
                    info: {
                      text: item.title,
                      description: item.body?.summary
                    }
                  }
                );
              }));
              //slides.push(...slides);
              return (
                <section className={classNames('section_content', 'block_2_section', 'scroll_to_' + sectionCount)
                }>
                  <ShapeBackground
                    top={block.field_border_top && 'left'}
                    color={block.field_backgroud_color === 'ice' ? 'catskill_white' : (block.field_backgroud_color === 'navy' ? 'dark_bleu' : 'white')}
                    bottomInfinite={block.field_infinite_border}
                    bottom={block.field_border_bottom_display}
                  >
                    <div className={classNames('wrapper_page_xs')}>
                      <TitleSection
                        title="Nos réalisations"
                        subtitle="Les actualités du groupe Colas, à travers le monde"
                        type="arrow"
                      />
                    </div>
                    <div className={classNames('wrapper_page_xs', 'only_desktop')}>
                      <Carousel slides={getReOrderedCarouselSlides(slides)} sliderMode="title_description" />
                    </div>
                    <div className={classNames('only_mobile_tablet')}>
                      <SliderComponent settings={slider_settings}>
                        {
                          slides.map((slide, index) => (
                            <div key={index}>
                              <div className="only_mobile">
                                {slide?.image &&
                                  <Img
                                    style={{ width: '100%' }}
                                    imgStyle={{ objectFit: 'cover' }}
                                    fluid={{ ...slide.image.childImageSharp.mobile, aspectRatio: 0.733333333 }}
                                  />
                                }
                              </div>
                              <div className="only_tablet">
                                {slide?.image &&
                                  <Img
                                    style={{ height: '100%', width: '100%' }}
                                    imgStyle={{ objectFit: 'cover' }}
                                    fluid={{ ...slide.image.childImageSharp.desktop, aspectRatio: 1 }}
                                  />
                                }
                              </div>
                              <div className="info_title_description">
                                <p>{slide.info.text}</p>
                                {slide.info.link && (
                                  <Button
                                    primary={true}
                                    label={slide.info.link_label}
                                    link={slide.info.link}
                                  />
                                )}
                              </div>
                            </div>
                          ))
                        }
                      </SliderComponent>
                    </div>
                  </ShapeBackground>
                </section>
              );

            case 'block_content__block_chiffres_clefs':
              // eslint-disable-next-line no-case-declarations
              const dataArr = [];
              block.relationships?.field_values?.forEach((item) => {
                dataArr.push({
                  number: item.field_chiffre,
                  title: item.field_legend?.processed
                });
              });
              return (
                <KeyNumbers
                  key={i}
                  title={block.field_title?.processed}
                  subtitle={block.body?.processed?.replace(/<p[^>]*>/g, '')}
                  sectionName={'scroll_to_' + sectionCount}
                  textColor="white"
                  NoPadding={true}
                  backgroundImg={getImage(imagesArray, block.relationships?.field_image?.drupal_internal__mid)?.publicURL}
                  datas={dataArr}
                  locale={block.langcode}
                />
              );
          }
        })}

      </div>
    </Layout >
  );
};

export const query = graphql`
query defaultTemplateQuery($locale: String!, $slug: String!, $imagesID: [Int!]) {
  pageData: allNodePage(
    filter: {langcode: {eq: $locale}, path: {alias: {eq: $slug}}}
  ) {
    edges {
      node {
        title
        langcode
        field_type
        field_padding
        field_sous_titre
        field_version_audio
        path {
          alias
          langcode
        }
        relationships {
          field_image {
            drupal_internal__mid
          }
          field_blocs {
            __typename
            ... on Node {
              ... on block_content__basic {
                id
                relationships {
                  field_image {
                    drupal_internal__mid
                  }
                }
                field_link {
                  title
                  url
                }
                field_no_padding
                field_alignment
                field_subtitle {
                  processed
                }
                field_title {
                  processed
                }
                field_alignment
              }
              ... on block_content__block_slider_innovation {
                id
                field_title {
                  processed
                }
                field_subtitle {
                  processed
                }
                body {
                  processed
                }
                relationships {
                  field_slides {
                    field_title {
                      processed
                    }
                    relationships {
                      field_image {
                        drupal_internal__mid
                      }
                      field_anchor {
                        drupal_internal__tid
                      }
                    }
                  }
                }
                field_backgroud_color
                field_infinite_border
                field_border_top
                field_border_bottom_display
                field_link {
                  title
                  url
                }
              }
              ... on block_content__block_texte {
                id
                field_title {
                  processed
                }
                body {
                  processed
                }
                field_subtitle {
                  processed
                }
                field_backgroud_color
                field_infinite_border
                field_border_top
                field_border_bottom_display
                relationships {
                  field_image {
                    drupal_internal__mid
                  }
                  field_section {
                    field_no_padding
                    field_alignment
                    relationships {
                      field_image {
                        drupal_internal__mid
                      }
                      field_bloc_texte {
                        field_theme_title
                        field_title {
                          processed
                        }
                        field_bloc_text_1 {
                          processed
                        }
                      }
                    }
                  }
                }
              }
              ... on block_content__block_slider_nos_activites {
                field_title {
                  processed
                }
                field_subtitle {
                  processed
                }
                field_border_top
                field_infinite_border
                field_border_bottom
                field_backgroud_color
                relationships {
                  field_nos_activites {
                    __typename
                    ... on Node {
                      ... on node__realisations {
                        title
                        body {
                          summary
                        }
                        relationships {
                          field_image {
                            drupal_internal__mid
                          }
                        }
                        path {
                          alias
                          langcode
                        }
                      }
                      ... on node__activites {
                        title
                        body {
                          summary
                        }
                        relationships {
                          field_image {
                            drupal_internal__mid
                          }
                        }
                        field_text_2 {
                          processed
                        }
                      }
                    }
                  }
                }
              }
              ... on block_content__block_chiffres_clefs {
                body {
                  processed
                }
                field_title {
                  processed
                }
                langcode
                relationships {
                  field_image {
                    drupal_internal__mid
                  }
                  field_values {
                    field_legend {
                      processed
                    }
                    field_chiffre
                  }
                }
              }
            }
          }
        }
        body {
          processed
        }
      }
    }
  }

  allImages: allMediaImage(
    filter: {langcode: {eq: "fr"}, drupal_internal__mid: {in: $imagesID}}
  ) {
    edges {
      node {
        drupal_internal__mid
        relationships {
          field_media_image {
            localFile {
              publicURL
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
                mobile: fixed(width: 456, height: 456) {
                  ...GatsbyImageSharpFixed
                }
                desktop: fixed(width: 1256, height: 814) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
        langcode
      }
    }
  }
  file(relativePath: { eq: "slider-image.png" }) {
    childImageSharp {
      mobile: fixed(width: 456, height: 456) {
        ...GatsbyImageSharpFixed
      }
      desktop: fluid {
        ...GatsbyImageSharpFluid
      }
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  }
  allMenu :   allMenuLinkContentMenuLinkContent(
    filter: {langcode: {eq: $locale}}
  ) {
    edges {
      node {
        drupal_parent_menu_item
        drupal_id
        langcode
        link {
          url
        }
        menu_name
        title
      }
    }
  }
}
`;

export default EngagementEnjeux;
